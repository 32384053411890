import React, { Component } from 'react';
import { Link } from "gatsby";
import "../styles/Home.css";
import ShareLinks from '../components/ShareLinks/ShareLinks.js';
import Layout from '../components/Layout.js';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { firstPage: null, latestPage: null };
  }

  fetchPages() {
    this.setState({ status: 'LOADING' });
    window.fetch('/api/pages/first')
      .then(response => response.json())
      .then((result) => {
        this.setState({ firstPage: result });
      }).catch(e => {
      console.error( e);
    });

    window.fetch('/api/pages/latest')
      .then(response => response.json())
      .then((result) => {
        this.setState({ latestPage: result });
      }).catch(e => {
      console.error( e);
    });
  }

  componentDidMount() {
    this.fetchPages();
  }

  render() {
    const { firstPage, latestPage } = this.state;

    function renderPage(_page, link) {
      const linkText = link === "first" ? "New Reader" : "Latest Page";
      const publishDate = new Date(_page.publishDate);
      const publishStr = _page.isPublished ? "Published: " + publishDate.toDateString() : "To be published: " + publishDate.toDateString();

      return (
        <div className="page-info">
          <img className="page-thumb" src={_page.filePath.replace("/pages/", "/thumbs/")}/>
          <div>
            <h2><Link to={`/comic/${link}`}>{linkText}</Link></h2>
            <p className="page-name">{`${_page.chapter.name}: ${_page.name}`}</p>
            <p className="publishMeta">{publishStr}</p>
          </div>
        </div>
      )
    }

    return (
      <Layout>
        <div className="content home">
          <div className="aux-info">
            <ShareLinks/>
            <div className="aux-info-item">
              { latestPage ? renderPage(latestPage, "latest") : null}
              { firstPage ? renderPage(firstPage, "first") : null}
              <p>
                <strong>Welcome</strong> to The Starless Land, a fantasy/adventure webcomic! The story grew from characters I invented around 2005 and has been through several iterations. It has taken over a decade for the pieces to fall into place enough that I feel confident cementing plot points. The plot has been influenced by a bunch of stories I've enjoyed over the years. As for the central four characters, each are drawn a little from me, and the symbol appearing behind them on the title page is also a hint to their personalities. I've chosen to leave them mostly mysterious until their actual introduction in the comic.
              </p>
              <p>
                Updates are on <del>Monday</del> Tuesday! My personal goal is still to get the page finished by the end of the day Monday, so that I can publish early Tuesday and put out messaging about the update.
              </p>
              <p>
                I haven't done much promotion of the comic yet, as it is still pretty early on. I got the last big website updates finished, but there is still a lot of art I want to add and little things to catch up on. I'm still learning how to keep the pace up enough that I don't need to go on hiatus to script new pages, but I'm starting to get a good system worked out. I'm really excited to be getting on towards the end of the first chapter, where some information gaps left by the prologue will be explained and we'll get some more tension in the plot.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Home;
